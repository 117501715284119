import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../../components/layout/layout'
import Header from '../../../components/header/header'
import Nav from '../../../components/nav/nav'
import Footer from '../../../components/footer/footer'
import styles from './paintings.module.css'

import earlyWorksThumb from '../../../assets/images/albums/paintings/paintings-early-works-thumbnail.jpg'
import recentWorksThumb from '../../../assets/images/albums/paintings/paintings-recent-works-thumbnail.jpg'
import worksOnPaperThumb from '../../../assets/images/albums/paintings/paintings-works-on-paper-thumbnail.jpg'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <article className={styles.wrapper}>
        <div className={styles.galleryItem}>
          <Link to="/albums/paintings/recent-works">
            <img
              src={recentWorksThumb}
              alt="A thumbnail for the recent works paintings gallery"
              className={styles.galleryThumbnail}
            />
          </Link>
          <h2 className={styles.caption}>Recent Works</h2>
        </div>

        <div className={styles.galleryItem}>
          <Link to="/albums/paintings/works-on-paper">
            <img
              src={worksOnPaperThumb}
              alt="A thumbnail for the works on paper gallery"
              className={styles.galleryThumbnail}
            />
          </Link>
          <h2 className={styles.caption}>Works On Paper</h2>
        </div>

        <div className={styles.galleryItem}>
          <Link to="/albums/paintings/early-works">
            <img
              src={earlyWorksThumb}
              alt="A thumbnail for the early works paintings gallery"
              className={styles.galleryThumbnail}
            />
          </Link>
          <h2 className={styles.caption}>Early Works</h2>
        </div>
      </article>
    </main>
    <Footer />
  </Layout>
)
